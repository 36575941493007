@mixin v-spaces($spaces) {
    @each $space, $value in $spaces {
        .v-space-#{$space} {
            margin-top: $value;
        }

        .v-space-screen-#{$space} {

            @include breakpoint($screen-xs) {
                margin-top: $value;
            }
        }

        .v-padding-#{$space} {
            padding-top: $value !important;
            padding-bottom: $value !important;
        }

        .h-padding-#{$space} {
            padding-left: $value;
            padding-right: $value;
        }

        .hl-padding-#{$space} {
            padding-left: $value;
        }

        .h-space-#{$space} {
            margin-left: $value !important;
        }

        .all-padding-#{$space} {
            padding: $value;
        }
    }
}

@mixin responsive-v-spaces($breakpoints) {
    @each $bp, $bpValue in $breakpoints {

        @include breakpoint($bpValue) {

            @each $space, $value in $spaces {
                .v-space-#{$space}-screen-#{$bp} {
                    margin-top: $value;
                }
            }
        }
    }
} 

@include v-spaces($spaces);
@include responsive-v-spaces($breakpoints);

.h-padding-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.all-margin-0 {
    margin: 0;
}