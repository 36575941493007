.filter-types {
    &__header {
        position: relative;

        &.mod-left-arrow:before {
            left: map-get($spaces, md);
            transform: initial;
        }

        &:before {
        content: '';
        width: 0px;
        height: 0px;
        position: absolute;
        bottom: -8px;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-top: 9px solid $color-primary;
        left: 50%;
        transform: translateX(-50%);
        }   
    }

    &__table {
        th,td {
            vertical-align: baseline !important;
        }

        .heading {
            margin: 0;
        }

    }


    .row {

        &__location {
            font-family: $headings-font-family;
            font-size: 18px;
        }
        
        &__performance {
            font-size: 18px;
        }
    }
}