$color-primary: #05817a;
$color-secondary: #e4be84;
$color-black-text: #111;
$color-gray-80: change-color($color-black-text, $lightness: 30);
$color-gray-60: change-color($color-black-text, $lightness: 40);
$color-gray-40: change-color($color-black-text, $lightness: 60);

$color-success: #8eb467;

$color-base-bg: #fff;
$color-light-bg: #ededed;

$space-xl: 72px;
$space-m: 18px;


// maps for helper classes

$colors: (
    green: #05817a,
    yellow: #e4be84,
    black: #111,
    black-opacity-60: rgba(#111, 0.6),
    white: #fff,
    gray-80: $color-gray-80,
    gray-60: $color-gray-60,
    success: $color-success,
    danger: $brand-danger
);

$spaces: (
    xs: 4px,
    sm: 9px,
    md: 18px,
    lg: 36px,
    xl: 72px,
    xxl: 144px
);

$icon-sizes: (
    sm: 18px,
    md: 36px,
    lg: 72px,
    xl: 144px
);

$breakpoints: (
    xs: 480px,
    sm: 768px,
    md: 992px,
    lg: 1200px
);

// breakpoints
@include breakpoint-set('default feature', max-width);
$breakpoint-to-ems: true;

$screen-xs:                  480px;

// Small screen / tablet
$screen-sm:                  768px;

// Medium screen / desktop
$screen-md:                  992px;

// Large screen / wide desktop
$screen-lg:                  1200px;



// overwriting bootstrap default font-family
$headings-font-family: 'Oswald', sans-serif;
//$headings-font-weight: 700;

