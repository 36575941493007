.icon {
    height: 36px;
    max-width: 100%;
    display: inline-block;
}
@mixin icon-sizes($icons) {
    @each $size, $value in $icons {
        .icon-#{$size} {
            height: $value;
        }

        .icon-#{$size}-fixed-w {
            width: $value;
        }
    }
}

@include icon-sizes($icon-sizes);